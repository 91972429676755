<template>
    <div class="sidebar-wrapper" :class="expanded ? 'sidebar-wrapper--expanded' : null">
        <div class="sidebar" :class="expanded ? 'sidebar--expanded' : null">
            <button
                    class="sidebar__toggler"
                    :class="expanded ? 'sidebar__toggler--expanded' : null"
                    @click.prevent="$store.commit('toggleSidebar', !expanded)"
                    v-if="!isTablet"
            >
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7.41"
                        height="12"
                        viewBox="0 0 7.41 12"
                >
                    <g>
                        <path
                                fill="#2d2d2d"
                                d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
                                transform="rotate(180 8 8.875)"
                        />
                    </g>
                </svg>
            </button>
            <div class="sidebar__wrap">
                <MiniAccout class="sidebar__mini-account" v-if="!isMobile"/>

                <div class="sidebar__sidebar-menu sidebar-menu">
                    <ul class="sidebar-menu__list">

                        <MenuItem
                                v-for="item in arr_menu_items" :key="item.id"
                                :label="item.label"
                                :route="item.link"
                                :icon="item.icon"
                                :notice_count="item.notice_count"
                        />

                    </ul>
                </div>
            </div>

            <div @click.prevent="$store.commit('toggleSidebar', false)" class="sidebar-shadow"
                 :class="expanded ? 'sidebar-shadow-expanded' : null"></div>
        </div>
    </div>
</template>

<script>
import MenuItem from "./MenuItem";
import MiniAccout from "./MiniAccount";

export default {
    components: {
        MenuItem,
        MiniAccout,
    },
    computed: {
        expanded() {
            return this.$store.state.showSidebar;
        },

        arr_menu_items() {
            const arr = [];

            arr.push({
                id: 1,
                link: '/',
                label: this.$t("links.main"),
                notice_count: 0,
                order: 0,
                icon: require('@/assets/img/icons/home.svg')
            });

            if (this.permissions['settings.access']) {
                arr.push({
                    id: 2,
                    link: '/settings',
                    label: this.$t("links.settings"),
                    notice_count: 0,
                    order: 1,
                    icon: require('@/assets/img/icons/settings.svg')
                });
            }
            if (this.permissions['employees.access']) {
                arr.push({
                    id: 15,
                    link: '/employees',
                    label: this.$t("links.employees"),
                    notice_count: 0,
                    order: 2,
                    icon: require('@/assets/img/icons/team.svg')
                });
            }
            if (this.permissions['establishments.access']) {
                arr.push({
                    id: 22,
                    link: '/establishments',
                    label: this.$t("links.establishments"),
                    notice_count: 0,
                    order: 3,
                    icon: require('@/assets/img/icons/places.svg')
                });
            }
            if (this.permissions['pupils.access']) {
                arr.push({
                    id: 27,
                    link: '/pupils',
                    label: this.$t("links.pupils"),
                    notice_count: 0,
                    order: 4,
                    icon: require('@/assets/img/icons/students.svg')
                });
            }
            if (this.permissions['schedule.view']) {
                arr.push({
                    id: 34,
                    link: '/schedule',
                    label: this.$t("links.schedule"),
                    notice_count: 0,
                    order: 5,
                    icon: require('@/assets/img/icons/schedule.svg')
                });
            }
            arr.push({
                id: 40,
                link: '/accounting',
                label: this.$t("links.accounting"),
                notice_count: this.$store.state.accountingNotify,
                order: 6,
                icon: require('@/assets/img/icons/documents.svg')
            });
            if (this.permissions['reports.access']) {
                arr.push({
                    id: 44,
                    link: '/reports',
                    label: this.$t("links.reports"),
                    notice_count: 0,
                    order: 7,
                    icon: require('@/assets/img/icons/reports.svg')
                });
            }
            if (this.permissions['yearly.access']) {
                arr.push({
                    id: 60,
                    link: '/yearly-reports',
                    label: this.$t("links.annual"),
                    notice_count: 0,
                    order: 8,
                    icon: require('@/assets/img/icons/yearly.png')
                });
            }
            if (this.permissions['requests.access']) {
                arr.push({
                    id: 66,
                    link: '/requests',
                    label: this.$t("links.requests"),
                    notice_count: 0,
                    order: 9,
                    name: "requests",
                    icon: require('@/assets/img/icons/orders.svg')
                });
            }
            if (this.permissions['notifications.access']) {
                arr.push({
                    id: 67,
                    link: '/mail',
                    label: this.$t("links.notifications"),
                    notice_count: 0,
                    order: 10,
                    name: "messages",
                    icon: require('@/assets/img/icons/notifications.svg')
                });
            }
            arr.push({
                id: 82,
                link: '/support',
                label: this.$t("links.support"),
                notice_count: 0,
                order: 11,
                name: "support",
                icon: require('@/assets/img/icons/franchise.png')
            });
            arr.push({
                id: 102,
                link: '/calls',
                label: this.$t("links.calls"),
                notice_count: 0,
                order: 12,
                name: "calls",
                icon: require('@/assets/img/icons/phone.svg')
            });
            if (this.permissions['news.access']) {
                arr.push({
                    id: 68,
                    link: '/news',
                    label: this.$t("links.news"),
                    notice_count: 0,
                    order: 13,
                    icon: require('@/assets/img/icons/news.svg')
                });
            }
            if (this.isRoot) {
                arr.push({
                    id: 69,
                    link: '/franchise',
                    label: this.$t("links.clients"),
                    notice_count: 0,
                    order: 14,
                    name: "companies",
                    icon: require('@/assets/img/icons/franchise.png')
                });
            }


            for (let name in this.notifications) {
                const find = arr.find(el => el.name === name);
                if (!find) {
                    continue;
                }

                find.notice_count = this.notifications[name];
            }

            for (let i = 0; i < arr.length; i++) {
                const menu = arr[i];
                const find = this.$store.state.menus.find(el => el.id === menu.id);
                if (!find) {
                    continue;
                }

                if (Object.hasOwn(find, "order")) {
                    menu.order = find.order;
                }
                if (!find.enabled && find.id !== 2) {
                    arr.splice(i, 1);
                    i--;
                }
            }
            arr.sort((a, b) => a.order - b.order);

            return arr;
        }

    },

    props: {
        notifications: {
            type: Object,
            default: () => ({})
        }
    },

    mounted() {
        const now = Math.round(Date.now() / 1000);
        const time = 10 * 60;
        const consCount = JSON.parse(localStorage.getItem("consCount") || "{}");
        if (!consCount.time || now - consCount.time > time) {
            fetch(this.location + "/consultation/count")
                .then(data => data.json())
                .then(data => {
                    const count = data.data;
                    localStorage.setItem("consCount", JSON.stringify({
                        count,
                        time: now
                    }));
                    this.$store.commit("setAccountingNotify", count);
                });
        } else {
            this.$store.commit("setAccountingNotify", consCount.count);
        }
    },

    watch: {
        expanded(expanded) {
            if (this.isTablet) {
                if (expanded) document.documentElement.style.overflowY = "hidden";
                else document.documentElement.style.overflowY = "scroll";
            }
        },
        $route() {
            if (this.isTablet)
                this.$store.commit("toggleSidebar", (this.expanded = false));
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/mixins";
@import "@/assets/scss/functions";
@import "@/assets/scss/variables";

:root {
  --sidebar-width: 115px;
  --sidebar-width-expanded: 290px;
}

.sidebar-wrapper {
  @media(min-width: 1500px) {
    flex: 0 1 var(--sidebar-width);
    min-width: var(--sidebar-width);
    transition: 250ms flex, 250ms min-width;
    &--expanded {
      flex: 0 1 var(--sidebar-width-expanded);
      min-width: var(--sidebar-width-expanded);
    }

  }
}

.sidebar {
  background-color: #fff;
  min-width: var(--sidebar-width);
  transition: 250ms flex, 250ms min-width;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1000;

  @media(min-width: 1100px) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
  }

  & * {
    transition: width 250ms;
  }

  @include tablet {
    width: 100%;
    position: fixed;
    left: -105vw;
    z-index: 100;
    top: 0;
    transition: 450ms;
    box-shadow: none;
  }

  &:not(.sidebar--expanded) {
    .sidebar-menu__link-icon {
      margin-right: 0;
    }

    .sidebar-menu__link {
      font-size: 0;
    }

    .mini-account__username {
      opacity: 0;
      overflow: hidden;
    }
  }

  &--expanded {
    flex: 0 1 var(--sidebar-width-expanded);
    min-width: var(--sidebar-width-expanded);
    transition: 250ms flex, 250ms min-width;

    .sidebar__wrap {
      width: var(--sidebar-width-expanded);
    }

    @include tablet {
      left: 0;
      transition: 450ms;

      .sidebar__wrap {
        width: var(--sidebar-width-expanded);
      }
    }
  }

  @media(max-width: 600px) {
    .sidebar__wrap {
      width: var(--sidebar-width-expanded);
    }
  }

  &__wrap {
    overflow-y: scroll;
    height: 100vh;
    width: var(--sidebar-width);
    background: #fff;
    position: fixed;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;

    @include tablet {
      padding-bottom: rem(200px);
      width: var(--sidebar-width-expanded);
    }
    @media(max-width: 600px) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
    }
  }

  &__toggler {
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    right: -15px;
    top: 115px;
    z-index: 1001;
    border-radius: 50%;

    svg {
      transform: rotate(180deg);
      transition: 350ms;
    }

    &--expanded {
      svg {
        transform: none;
        transition: 350ms;
      }
    }
  }
}

@media(max-width: 600px) {
  .sidebar {
    &--expanded .sidebar__wrap {
      width: 100%;
    }
  }

}

.sidebar--expanded {
  & .sidebar-menu__notice {
    opacity: 1;
  }
}

.sidebar-shadow {
  display: none;
  transition: .2s;

  &:not(.sidebar-shadow-expanded) {
    pointer-events: none;
    opacity: 0;
  }

  @media(max-width: 1500px) {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  @media(max-width: 1100px) {
    z-index: 99;
  }
}

.sidebar-menu {
  &__list {
    @include unlist;
  }
}

.sidebar__wrap {
  position: relative;
  z-index: 1000;
}
</style>