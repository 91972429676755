export function timestampToDate(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);

    return date;
}

export function timestampToString(timestamp, time = false, days = true) {
    return dateToString(timestampToDate(timestamp), time, days);
}

/**
 *
 * @param date {Date}
 * @param time {boolean} - показывать ли время
 * @param days {boolean} - показывать ли дату
 * @return {string} - Формат [год-месяц-день] [часы:минуты]
 */
export function dateToString(date, time = false, days = true) {
    const year = date.getFullYear();
    const day = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);


    let ret = "";
    if (days) {
        ret += day.substring(day.length - 2) + "/" + month.substring(month.length - 2) + "/" + year;
    }
    if (time) {
        const minutes = "0" + date.getMinutes();
        const hours = "0" + date.getHours();
        ret += ` ${hours.substring(hours.length - 2)}:${minutes.substring(minutes.length - 2)}`;
    }

    return ret.trim();
}

/**
 *
 * @param date {Date}
 */
export function dateToTimestamp(date) {
    return Math.round(date.getTime() / 1000);
}

/**
 * @param string {string} - Формат день/месяц/год [часы?:мин?:сек?]
 * @return {Date}
 */
export function stringToDate(string) {
    const split = string.split(" ");

    const dateInfo = split[0].split("/");
    const date = new Date();
    date.setDate(+dateInfo[0]);
    date.setMonth(+dateInfo[1] - 1);
    date.setFullYear(+dateInfo[2]);
    if (split.length > 1) {
        const timeInfo = split[1].split("-");
        if (timeInfo.length > 0) {
            date.setHours(+timeInfo[0]);
        }
        if (timeInfo.length > 1) {
            date.setMinutes(+timeInfo[1]);
        }
        if (timeInfo.length > 2) {
            date.setSeconds(+timeInfo[2]);
        }
    }

    return date;
}

/**
 *
 * @param date {Date}
 */
export function getFullMonth(date) {
    const month = "0" + String(date.getMonth() + 1);
    return month.substring(month.length - 2);
}

/**
 *
 * @param date {Date}
 */
export function getFullDate(date) {
    const day = "0" + String(date.getDate());
    return day.substring(day.length - 2);
}

/**
 *
 * @param date {string}
 */
export function setDefaultDateFormat(date) {
    const split = date.split("/");
    [split[0], split[2]] = [split[2], split[0]];
    return split.join("-");
}

/**
 * Not in-place
 * @param date {Date}
 * @return {Date}
 */
export function getLastMonthDay(date) {
    if (date.getMonth() === 11) {
        return new Date(date.getFullYear(), 11, 31);
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

/**
 * Not in-place
 * @param date {Date}
 * @return {Date}
 */
export function getStartMonthDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 *
 * @param firstDate {Date}
 * @param secondDate {Date}
 * @param compareTime
 */
export function compareDates(firstDate, secondDate, compareTime = false) {
    if (firstDate === null || secondDate === null) {
        return 0;
    }

    if (firstDate.getFullYear() !== secondDate.getFullYear()) {
        return firstDate.getFullYear() - secondDate.getFullYear();
    }
    if (firstDate.getMonth() !== secondDate.getMonth()) {
        return firstDate.getMonth() - secondDate.getMonth();
    }
    if (firstDate.getDate() !== secondDate.getDate()) {
        return firstDate.getDate() - secondDate.getDate();
    }
    if (!compareTime) {
        return 0;
    }

    if (firstDate.getHours() !== secondDate.getHours()) {
        return firstDate.getHours() - secondDate.getHours();
    }
    if (firstDate.getMinutes() !== secondDate.getMinutes()) {
        return firstDate.getMinutes() - secondDate.getMinutes();
    }

    return 0;
}

/**
 * @param date {Date}
 * @param days {number}
 * @param inPlace {boolean}
 */
export function addDays(date, days, inPlace = true) {
    if (!inPlace) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    let newDate = date.getDate() + days;
    if (newDate > 28) {
        let month = date.getMonth();
        const maxDay = getLastMonthDay(date);
        if (newDate > maxDay.getDate()) {
            newDate -= maxDay.getDate();
            month++;
        }
        if (month > 11) {
            date.setFullYear(date.getFullYear() + 1);
            month = 0;
        }
        date.setMonth(month, newDate);
    } else if (newDate < 1) {
        let month = date.getMonth();
        month--;
        if (month < 0) {
            month = 11;
            date.setFullYear(date.getFullYear() - 1);
        }
        date.setMonth(month);
        const maxDay = getLastMonthDay(date);

        newDate += maxDay.getDate();
        date.setDate(newDate);
    } else {
        date.setDate(newDate);
    }

    return date;
}

/**
 * Not in-place
 * @param time {number} - Время в минутах с начала суток
 * @param date {Date} - Для какой даты проставить время
 * @return {Date}
 */
export function minutesToDate(time, date) {
    date = new window.Date(date);
    date.setHours(Math.floor(time / 60));
    date.setMinutes(time % 60);

    return date;
}

/**
 *
 * @param date
 * @return {number}
 */
export function dateToMinutes(date) {
    if (!(date instanceof window.Date)) {
        date = new window.Date(date.format("YYYY-MM-DD HH:mm:ss"));
    }
    return date.getHours() * 60 + date.getMinutes();
}

/**
 *
 * @param date
 * @return {Window.Date|Date}
 */
export function momentToDate(date) {
    if (!(date instanceof window.Date)) {
        date = new window.Date(date.format("YYYY-MM-DD HH:mm:ss"));
    }

    return date;
}